<template>
  <div v-if="order" class="mb-3">
    <v-sheet outlined>
      <v-row no-gutters>
        <v-col cols="6">
          <span class="h4">ID: {{ order.orderId.substr(0, 4) }}</span>
        </v-col>
        <v-col cols="6">
          <span class="h4">Status: {{ order.status.replace('_', ' ') }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <span class="h4">Customer: {{ order.buyer.name }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <span class="h4">Producer: {{ order.seller.name }}</span>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet outlined class="pa-1 my-5">
      <v-row class="space-between">
        <v-col cols="6" sm="3" lg="2">
          <span class="h4"
            ><v-btn
              :disabled="!canCancel"
              color="info white--text"
              @click.prevent="onCancelOrder"
              >Cancel Order <v-icon right>mdi-cancel</v-icon></v-btn
            >
          </span>
          <span class="caption d-flex"
            >Cancels customer and producer orders. You may refund</span
          >
        </v-col>
        <v-col cols="6" sm="3" lg="2" v-if="false">
          <span class="h4"
            ><v-btn color="info white--text"
              >Re Order <v-icon right>mdi-cart-arrow-right</v-icon></v-btn
            >
            <span class="caption d-flex"
              >Create a copy of this order and submit again .</span
            >
          </span>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <span class="h4"
            ><v-btn
              color="info white--text"
              :disabled="!canResubmitDelivery"
              @click="onResubmitDelivery"
              >Resubmit Delivery <v-icon right>mdi-truck-outline</v-icon>
            </v-btn>
            <span v-if="order.delivery.newDeliveryTime" class="body-2">
              Submitted {{ $moment(order.delivery.newDeliveryTime).fromNow() }}
            </span>
          </span>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <span class="h4"
            ><v-btn
              color="info white--text"
              :disabled="!canCancelDelivery"
              @click="onCancelDelivery"
              >Cancel Delivery <v-icon right>mdi-close</v-icon></v-btn
            >
          </span>
        </v-col>
      </v-row>
      <v-row class=" space-between">
        <v-col cols="6" sm="3" lg="2">
          <span class="h4"
            ><v-btn
              color="info white--text"
              :disabled="!canCallRobot"
              @click="robotCall"
              >Call Robot <v-icon right>mdi-bullhorn</v-icon>
            </v-btn>
            <span class="caption d-flex"
              >Initiate one time robot call for testing or force to call .</span
            >
          </span>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <span class="h4">
            <v-btn
              color="info white--text"
              target="_blank"
              :href="
                `${siteLink}/api/order_voice/${order.sellerUserId}/${order.orderId}?format=text`
              "
              >Order Text <v-icon right>mdi-comment-text-outline</v-icon>
            </v-btn>
            <v-text-field
              v-model="phone"
              label="Or send sms to mobile"
              :disabled="submitting"
              :append-outer-icon="
                phone && phone.length > 0 ? 'mdi-send' : 'cancel'
              "
              @click:append-outer="sendSms"
            ></v-text-field>
          </span>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <span class="h4"
            ><v-btn color="info white--text" @click="clearErrors"
              >Clear Errors <v-icon right>mdi-check-underline</v-icon>
            </v-btn>
            <span class="caption d-flex"
              >Removes Delivery or payment errors if they are resolved.</span
            >
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-btn small color="primary white--text" @click="getDeliveryTrack"
            >Track Delivery <v-icon right>mdi-map</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" v-if="tracks">
          <template>
            <v-data-table
              dense
              :headers="track_headers"
              :items="tracks"
              item-key="updated"
              class="elevation-1"
            >
              <template v-slot:item.map="{ item }"
                ><PmDriver :delivery="item" />
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row v-if="order.admin_error">
      <v-col>
        Admin Error:
        <code class="body-1  error--text">{{ order.admin_error }}</code>
      </v-col>
    </v-row>

    <v-row v-if="order.delivery.error">
      <v-col>
        Delivery Error:
        <code class="body-1  error--text">{{ order.delivery.error }}</code>
      </v-col>
    </v-row>
    <v-sheet>
      <v-row
        ><v-col cols="4" md="3"
          >Platform: <b>{{ order.platform }}</b></v-col
        >
        <v-col cols="4" md="5"
          >Version: <b>{{ order.appVersion }}</b></v-col
        >
        <v-col cols="4" md="3" v-if="order.actionPlatform"
          >By: <b>{{ order.actionPlatform }}</b></v-col
        >
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel v-if="order.call_log">
          <v-expansion-panel-header><h4>Call Log</h4></v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre class="small">  {{ order.call_log }}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
  </div>
</template>

<script>
import firebase from '@/firebase-init'
import axios from 'axios'
import PmDriver from '@/components/admin/pm/PmDriver.vue'
export default {
  components: {
    PmDriver
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      phone: null,
      submitting: false,
      tracks: null,
      track_headers: [
        { text: 'Status', value: 'status' },
        { text: 'Updated', value: 'updated' },
        { text: 'Dropoff ETA', value: 'dropoff_eta' },
        { text: 'Pickup ETA', value: 'pickup_eta' },
        { text: 'Map', value: 'map' }
      ]
    }
  },
  computed: {
    canCancel() {
      return (
        this.order.status !== 'delivered' &&
        this.order.status !== 'picked_up' &&
        this.order.status !== 'rejected' &&
        this.order.status !== 'cancelled'
      )
    },
    canCancelDelivery() {
      return (
        this.order.deliveryMethod === 'delivery' &&
        this.order.status !== 'new' &&
        this.order.delivery &&
        this.order.delivery.delivery_track &&
        this.order.delivery.delivery_track.id
      )
    },
    canResubmitDelivery() {
      return this.order.status !== 'new' && this.order.delivery
    },
    canRefund() {
      return this.order.status !== 'new' && this.order.status !== 'declined'
    },
    canCallRobot() {
      return (
        this.order.status !== 'rejected' && this.order.status !== 'cancelled'
      )
    },
    siteLink() {
      return `https://${process.env.VUE_APP_DOMAIN}`
    }
  },
  methods: {
    async onCancelOrder() {
      if (confirm('Are you sure want to cancel the orders?')) {
        let request = {
          uid: this.order.buyerUserId,
          response: 'cancelled by admin',
          id: this.order.orderId,
          status: 'cancelled',
          collection: 'buyer-orders'
        }
        await this.$store.dispatch('setOrderStatus', request)
        request = {
          uid: this.order.sellerUserId,
          response: 'cancelled by admin',
          id: this.order.orderId,
          status: 'cancelled',
          collection: 'seller-orders'
        }
        await this.$store.dispatch('setOrderStatus', request)
      }
    },
    showRefundBox() {
      this.$emit('showRefundBox', true)
    },
    onResubmitDelivery() {
      if (confirm('Are you sure want to resubmit delivery request?')) {
        this.$store.dispatch('resubmitDeliveryRequest', {
          id: this.order.orderId,
          uid: this.order.sellerUserId
        })
        this.$store.dispatch('setMessage', {
          title: 'Delivery Request Submitted',
          body: 'Thanks'
        })
      }
    },
    onCancelDelivery() {
      if (confirm('Are you sure want to cancel the orders?')) {
        this.$store.dispatch('setDeliveryRequest', {
          id: this.order.orderId,
          uid: this.order.sellerUserId,
          code: 'cancelling'
        })
        this.$store.dispatch('setMessage', {
          title: 'Delivery Cancel Request Submitted',
          body: 'Thanks'
        })
      }
    },
    clearErrors() {
      let payload = {
        error: null,
        delivery: {}
      }
      if (this.order.delivery && this.order.delivery.error) {
        payload.delivery.error = null
      }
      if (
        this.order.delivery &&
        this.order.delivery.delivery_track &&
        this.order.delivery.delivery_track.error
      ) {
        payload.delivery.delivery_track = null
      }
      firebase
        .firestore()
        .collection('orders')
        .doc(this.order.sellerUserId)
        .collection('seller-orders')
        .doc(this.order.orderId)
        .set(payload, { merge: true })

      firebase
        .firestore()
        .collection('orders')
        .doc(this.order.buyerUserId)
        .collection('buyer-orders')
        .doc(this.order.orderId)
        .set(payload, { merge: true })
    },
    robotCall() {
      if (confirm('Are you sure want to initiate the robot call?')) {
        let request = {
          id: this.$route.params.order_id,
          uid: this.order.sellerUserId,
          action: 'robotCall',
          collection: 'seller-orders'
        }

        this.$store.dispatch('setOrderStatus', request)

        this.$store.dispatch('setMessage', {
          title: 'Request Submitted',
          body: 'Thanks'
        })
      }
    },
    async sendSms() {
      if (this.phone && this.phone.length > 8) {
        console.log('Sending sms to ', this.phone)
        this.submitting = true
        const token = await firebase.auth().currentUser.getIdToken()
        const backend = `https://biz.${process.env.VUE_APP_DOMAIN}`
        // const backend = `http://localhost:5000/takein-dev/us-central1`
        const targetUrl = `${backend}/sendOrderUrl?producerId=${this.order.sellerUserId}&orderId=${this.order.orderId}&phone=${this.phone}`
        await axios
          .post(
            targetUrl,
            {},
            {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }
          )
          .then(d => {
            this.submitting = false
            if (d.status == 200) {
              this.$store.dispatch('setMessage', {
                title: 'Success',
                body: 'Delivery Cancelled'
              })
            }
          })

          .catch(e => {
            this.submitting = false
            console.error(e)
          })
      }
    },
    async getDeliveryTrack() {
      this.tracks = await this.$store.dispatch('getDeliveryTrack', {
        uid: this.order.sellerUserId,
        orderId: this.order.orderId
      })
    }
  }
}
</script>
